<template>
  <div>
    <BlockTitle v-if="tableData.sheetName" :title="tableData.sheetName" :is-bold="true" class="msg-title" />
    <a-table
      :row-key="(row) => {return row.id + ''}"
      :columns="columns"
      :data-source="interception"
      :scroll="scroll"
      :pagination="false"
    />
  </div>
</template>

<script>
export default {
  name: 'Extable',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index')
  },
  props: {
    tableData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      columns: [],
      interception: [],
      scroll: { x: 0, y: 360 }
    }
  },
  mounted() {
    console.log(this.tableData)
    const { errorList = [], dataList = [], headColumn = [] } = this.tableData
    if (errorList.length > 0) {
      this.initTable(errorList, headColumn)
      return
    }
    this.initTable(dataList, headColumn)
  },
  methods: {
    initTable(interception, headColumn) {
      this.scroll.x = 0
      const a = { 1: 1200, 2: 500, 3: 360, 4: 240 }
      if (!headColumn) return
      const columns = headColumn.map(({ title, field }) => {
        return {
          title,
          dataIndex: field,
          key: field,
          align: 'center',
          width: headColumn.length <= 4 ? a[headColumn.length] : 180
        }
      })
      columns.unshift({
        title: '行号',
        dataIndex: 'lineNum',
        key: 'lineNum',
        fixed: 'left',
        width: 50
      })
      columns.push({
        title: '异常详情',
        dataIndex: 'details',
        key: 'details',
        fixed: 'right',
        width: 400

      })
      this.columns = columns
      this.columns.forEach(item => {
        this.scroll.x = Number(item.width) + this.scroll.x
      })
      this.interception = interception.map(({ data, details }, id) => {
        console.log(data)
        return {
          ...data,
          lineNum: id + 1,
          details,
          id
        }
      })
    },
    clear() {
      this.columns = []
      this.interception = []
    }
  }
}
</script>

<style lang="less" scoped>
.msg-title{
  margin-bottom: 12px;
}
/deep/.ant-table{
  margin-bottom: 12px;
}
</style>
