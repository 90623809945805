<template>
  <div class="container">
    <a-alert
      :style="{ background: `${typeList[type].bgColor}`, border: `1px solid ${typeList[type].borderColor}`}"
      :banner="banner"
      :closable="closable"
      :show-icon="showIcon"
      @close="onClose"
    >
      <template #icon>
        <a-icon :type="typeList[type].name" theme="filled" :style="{ color: `${typeList[type].color }`}" />
      </template>
      <!-- 内容区域插槽 -->
      <template slot="description">
        <slot name="content" />
      </template>
    </a-alert>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    // 提示类型
    type: {
      type: String,
      default: 'warning'
    },
    // 提示图标是否显示
    showIcon: {
      type: Boolean,
      default: false
    },
    // 是否显示关闭按钮
    closable: {
      type: Boolean,
      default: false
    },
    // 是否用作顶部提示
    banner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeList: {
        warning: {
          name: 'exclamation-circle',
          color: '#ED7B2F',
          bgColor: 'rgba(237, 123, 47, 0.1)',
          borderColor: 'rgba(237, 123, 47, 0.5)'
        },
        error: {
          name: 'exclamation-circle',
          color: '#E34D59',
          bgColor: 'rgba(227, 77, 89, 0.1)',
          borderColor: 'rgba(227, 77, 89, 0.5)'
        },
        info: {
          name: 'info-circle',
          color: '#5179FB',
          bgColor: 'rgba(81, 121, 251, 0.1)',
          borderColor: 'rgba(81, 121, 251, 0.5)'
        },
        success: {
          name: 'check-circle',
          color: '#00A870',
          bgColor: 'rgba(0, 168, 112, 0.1)',
          borderColor: 'rgba(0, 168, 112, 0.5)'
        }
      }
    }
  },
  methods: {
    onClose(e) {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-alert {
  width: 100%;
  height: auto;
  border-radius: 6px !important;
  padding: 10px 20px 10px 38px!important;
  position: relative;
  display: flex;
  align-items: center;
}
/deep/ .ant-alert-description {
  color: @sc-grey-100;
  font-weight: normal;
  .font-size(14px);
  padding-left: 8px;
}
/deep/ .ant-alert-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
  top: -1px;
  svg{
    width: 100%;
    height: 100%;
  }
  .anticon {
    width: 100%;
    height: 100%;

  }
}
/deep/ .ant-alert-close-icon {
  top: 7px;
  right: 20px;
}
/deep/ .ant-btn {
  height: 22px;
}
</style>
