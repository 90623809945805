<template>
  <div
    ref="anchor"
    class="anchor-wrapper beauty-scroll anchor-beauty-scroll"
  >
    <div class="anchor-begin" />
    <anchor-tree ref="anchorTree" :title-list="titleList" @locateTitle="handleLocateTitle" />
    <div class="anchor-end" />
    <div class="acitve-anchor-circle" :style="{ top: activeAnchorTop + 'px', left: '13px' }">
      <div class="mark" />
    </div>
  </div>
</template>

<script>
import AnchorTree from './anchorTree.js'
import { EventListener, nestToSimple, getRelativePosition } from './utils.js'
export default {
  name: 'Anchor',
  provide() {
    return {
      anchor: this
    }
  },
  components: {
    AnchorTree
  },
  props: {
    // 标题列表
    titleList: {
      type: Array,
      default: () => []
    },
    // 滚动内容的外层容器
    container: {
      type: String,
      default: ''
    }
    // 相对于页面的位置
    // position: {
    //   type: Object,
    //   default: () => ({})
    // }
  },
  data() {
    return {
      scrollContainer: null,
      scrollEle: null,
      allTitleOffsetTop: [],
      currentAnchorId: null,
      activeAnchorTop: 0,
      anchorPosition: {
        left: 0,
        top: 0,
        position: 'fixed'
      }
    }
  },
  computed: {
    simpleTitleList() {
      return this.nestToSimple(this.titleList)
    }
  },
  mounted() {
    this.getScrollObj()
    EventListener.add(this.scrollContainer, 'scroll', this.handleScroll)
    // this.computedAnchorPosition()
    // EventListener.add(window, 'scroll', this.handleWindowScroll)
    if (this.simpleTitleList.length > 0) {
      this.currentAnchorId = this.simpleTitleList[0].id
      this.setActiveAnchorCirclePosition()
      this.getAllTitleOffsetTop()
    }
  },
  beforeDestroy() {
    // EventListener.remove(window, 'scroll', this.handleWindowScroll)
    EventListener.remove(this.scrollContainer, 'scroll', this.handleScroll)
  },
  methods: {
    nestToSimple,
    // 滚动内容在浏览器可视区域时对应的锚点导航才可以在可视区域
    // handleWindowScroll() {
    //   this.computedAnchorPosition()
    // },
    // 计算锚点导航位置
    // computedAnchorPosition() {
    //   this.anchorPosition.top = `${this.position.top}px`
    //   this.anchorPosition.left = `${this.position.left}px`
    //   this.anchorPosition.position = this.position.position
    // },
    // 获取滚动内容的外层容器对象及DOM
    getScrollObj() {
      if (this.container) {
        this.scrollContainer = document.querySelector(this.container)
        this.scrollEle = this.scrollContainer
      } else {
        this.scrollContainer = window
        this.scrollEle = document.documentElement || document.body
      }
    },
    // 点击锚点将页面定位对应的标题处，并改变路径hash值
    handleLocateTitle(data) {
      const titleEle = document.querySelector(`#${data.id}`)
      this.scrollEle.scrollTop = titleEle.offsetTop
      setTimeout(() => {
        this.currentAnchorId = data.id
        this.setUrlHash(this.currentAnchorId)
        this.setActiveAnchorCirclePosition()
      })
    },
    // 设置路径hash
    setUrlHash(hash) {
      const location = window.location
      window.location.href = `${location.origin}${location.pathname}${location.search}#${hash}`
    },
    // 监听滚动过程中做了两件事：
    // 1、改变当前激活的锚点
    // 2、监听当前激活锚点变化并改变路径hash
    handleScroll(e) {
      const scrollTop =
        this.scrollContainer === window
          ? document.documentElement.scrollTop || document.body.scrollTop
          : e.target.scrollTop
      this.getCurrentAnchor(scrollTop)
      // this.computedAnchorPosition()
    },
    // 获取所有锚点对应标题在滚动内容区域中距离顶部的距离
    getAllTitleOffsetTop() {
      let i = -1
      const len = this.simpleTitleList.length
      if (this.scrollContainer === window) {
        while (++i < len) {
          this.allTitleOffsetTop.push(
            document.querySelector(`#${this.simpleTitleList[i].id}`).offsetTop -
              this.scrollEle.offsetTop
          )
        }
      } else {
        while (++i < len) {
          this.allTitleOffsetTop.push(
            document.querySelector(`#${this.simpleTitleList[i].id}`).offsetTop
          )
          this.allTitleOffsetTop = Array.from(new Set(this.allTitleOffsetTop))
        }
      }
    },
    // 根据内容滚动距离获取当前锚点数据
    getCurrentAnchor(scrollTop) {
      let i = -1
      const len = this.simpleTitleList.length
      while (++i < len) {
        const curAnchorScrollTop = this.allTitleOffsetTop[i]
        const nextAnchorScrollTop = this.allTitleOffsetTop[i + 1]
        if (
          (scrollTop >= curAnchorScrollTop &&
            nextAnchorScrollTop &&
            scrollTop < nextAnchorScrollTop) ||
          (scrollTop >= curAnchorScrollTop && !nextAnchorScrollTop)
        ) {
          this.currentAnchorId = this.simpleTitleList[i].id
          break
        }
      }
      this.setActiveAnchorCirclePosition()
    },
    // 设置表示当前激活锚点的小球的位置
    setActiveAnchorCirclePosition() {
      this.$nextTick(() => {
        const currentAnchorEl = this.$refs.anchor.querySelector('.anchor-item-wrapper.is-active')
        if (currentAnchorEl) {
          const relativePositionY = getRelativePosition(currentAnchorEl, this.$refs.anchor).y
          this.activeAnchorTop = relativePositionY + currentAnchorEl.clientHeight / 2
          this.$refs.anchor.scrollTo({
            left: 0,
            top: relativePositionY - 40 < 0 ? 0 : relativePositionY - 40,
            behavior: 'smooth'
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.anchor-wrapper {
  padding-bottom: 10px;
  overflow-y: auto;
  // position: fixed;
  .anchor-begin {
    position: relative;
    left: 12px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #e0e0e0;
    &::after {
      content: '';
      position: absolute;
      background-color: #fff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
  .anchor-end {
    position: relative;
    left: 12px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #e0e0e0;
    &::after {
      content: '';
      position: absolute;
      background-color: #fff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
  .acitve-anchor-circle {
    position: absolute;
    transform: translate(-40%, -45%);
    .mark{
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate(-115%, -50%);
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: @sc-primary-100;
    }
  }
}

.anchor-beauty-scroll {
  &::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important;
  }
}
</style>
