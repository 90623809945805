<template>
  <div class="blocktitle">
    <div class="title" :style="{'fontWeight': `${isBold ? 'bold' : '500'}`}">
      {{ title }}
      <slot name="left" />
    </div>
    <slot name="center" />
    <slot name="right" />
  </div>
</template>
<script>
export default {
  name: 'CbBlockTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    isBold: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.blocktitle{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title{
  padding-left: 7px;
  color: @sc-grey-100;
  margin-right: 10px;
  .font-size(16px);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 15px;
    background:  @sc-primary-100;
  }
}
</style>
