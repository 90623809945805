<!--
 * @Date: 2022-08-09 15:40:40
 * @descriotion:
-->
<template>
  <div class="percentage">
    <CBNumber
      v-model="showVal"
      suffix="%"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import math from '@/utils/math.js'
export default {
  name: 'Percentage',
  components: {
    CBNumber: () => import('./index')
  },
  inheritAttrs: true,
  model: {
    prop: 'defultVal',
    event: 'echoVal'
  },
  props: {
    defultVal: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      // 展示的数字
      showVal: '',
      // 实际的数字
      realVal: ''
    }
  },
  watch: {
    defultVal: {
      immediate: true,
      handler(val) {
        if (this.defultVal === '0' || this.defultVal === 0 || this.defultVal) {
          this.showVal = Number(math.multiply(Number(val), 100))
        } else {
          this.showVal = ''
        }
      }
    },
    showVal(val) {
      if (!val) { this.showVal = '' }
      this.realVal = Number(math.divide(Number(val), 100))
      this.$emit('change', this.realVal)
      this.$emit('echoVal', this.realVal)
    }
  },
  mounted() {
    console.log(this.defultVal)
  }
}
</script>

<style lang="less" scoped>

</style>
